<template>
    <button class="button rounded w-32 is-primary bg-transparent" style="border:solid 1px #353265;" @click="handlePress">
        <div class="flex items-center" style="color:#353265;">
            <i class="text-xl jam jam-map-marker"></i>
            <span class="text-sm">Abrir no mapa</span>
        </div>
    </button>


    <!--  b-button.w-full.rounded.p-0(type='is-primary'  @click='selectEstabelecimento(estabelecimento)')
                                            .flex.items-center
                                              b-icon.text-xl(pack='jam' icon='jam-shopping-cart')
                                              span.text-sm Adicionar ao carrinho -->
</template>
  
<script>
import { isIOS, isMobileDevice } from '../../utils';

function openMaps(params) {
    const { query } = params;
    const mapsQueryUrl = isIOS()
        ? `http://maps.apple.com/?q=daddr=${ query }`
        : `https://www.google.com/maps/search/?api=1&query=${ query }`;

    if (!query) throw new Error('Missing lat,long or query params in openMaps')

    if (isMobileDevice) window.location = mapsQueryUrl;
    else window.open(mapsQueryUrl, '_blank', "left=100,top=100,width=1024,height=1024");
}

export default {
    name: 'MapButton',
    props: {
        query: String,
        onBeforeOpenMaps: {
            type: Function,
            default: () => { },
        },
        onAfterOpenMaps: {
            type: Function,
            default: () => { },
        },
        title: {
            type: String,
            default: 'Abrir no mapa',
        },
        sx: Object,
    },
    methods: {
        handlePress() {
            this.onBeforeOpenMaps();
            if (this.query) {
                openMaps({
                    query: this.query,
                });
            } else {
                throw new Error('Missing lat,long or query params in MapButton');
            }
            this.onAfterOpenMaps();
        },
    }
};
</script>
