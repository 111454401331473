<template lang="pug">
form(@submit.prevent='handleSubmit()')
        .modal-card.max-w-sm.p-4(class='md:p-0')
            header.modal-card-head
                p.modal-card-title.flex.justify-content-center {{ model.id ? 'Editar' : 'Adicionar' }} Usuários
                button.delete(type='button' @click='modalProps.close()')
            section.modal-card-body
            
                b-field(style='margin-bottom:1.3rem' :message="model_message().nm_pessoa_fisica"
                    :type="{ 'is-danger': model.submitted && $v.model.nm_pessoa_fisica.$error }")
                    b-input(v-model='$v.model.nm_pessoa_fisica.$model' placeholder='Nome')
                b-field(style='margin-bottom:1.3rem' :message="model_message().nr_telefone"
                    :type="{ 'is-danger': model.submitted && $v.model.nr_telefone.$error }")
                    masked-input.input(v-model='$v.model.nr_telefone.$model' type='tel' :mask="phoneMask" placeholder="Telefone"
                        :class="{ 'is-danger': model.submitted && $v.model.nr_telefone.$error }" :guide='false')
                b-field(style='margin-bottom:1.3rem;' :message="model_message().dt_nascimento"
                    :type="{ 'is-danger': model.submitted && $v.model.dt_nascimento.$error }")
                    b-input(v-model='$v.model.dt_nascimento.$model' type='tel' v-mask="'##/##/####'" placeholder='Data de Nascimento')
                b-field(style='margin-bottom:1.3rem' :message="model_message().nr_cpf"
                    :type="{ 'is-danger': model.submitted && $v.model.nr_cpf.$error }")
                    b-input(v-model='$v.model.nr_cpf.$model' type='tel' v-mask="'###.###.###-##'" placeholder='CPF')
                
                b-field(label='Sexo')
                    b-radio(class='w-1/2'
                        v-for='options in options.ie_sexo'
                        :key='options.value'
                        v-model='$v.model.ie_sexo.$model'
                        :native-value='options.value'
                        name='sexo') {{ options.label }}
                .text-xs.text-red-500.w-full.flex.items-center.justify-end(v-if='model.submitted && $v.model.ie_sexo.$error')
                    i.mdi.mdi-alert-circle.mdi-24px.mr-1
                    span {{ model_message().ie_sexo }}

        footer.modal-card-foot
            .text-center.w-full
                b-button(class='w-2/3' type='is-primary' native-type='submit' rounded :loading='waiting.cadastrar') {{ model.id ? 'Salvar' : 'Adicionar' }}
</template>

<style lang="scss">
.field label .__note {
    font-size: .8em;
    font-weight: 300;
    color: #999;
}
</style>

<script>
import moment from 'moment'; moment.locale('pt-br')
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
import onlyNumbers, { validateCpf, validateFone, phoneMask } from './../../utils'
import { OutrasPessoas, Usuario, Utils } from '../../middleware'

const addNoteToField = (className, text) => {
    const aux = document.createElement('span')
    aux.classList.add('__note')
    aux.innerHTML = ` (${text})`
    const field = document.querySelector(`.field.${className} label`)
    field.appendChild(aux)
}

export default {
    props: ['modalProps'],
    data() {

        const model = {
            id: null,
            nm_pessoa_fisica: null,
            nr_cpf: null,
            nr_telefone: null,
            dt_nascimento: null,
            ie_sexo: null,
            submitted: false,
            reset() {
                Object.keys(this).forEach(key => {
                    if (!['reset'].includes(key)) this[key] = null
                    this.submitted = false
                })
            },
            is_titular: true
        }

        return {
            model,
            phoneMask,
            options: {
                ie_sexo: [
                    { value: 'M', label: 'Masculino' },
                    { value: 'F', label: 'Feminino' }
                ]
            },
            waiting: { cadastrar: false },
            should_validate_cpf: false
        }
    },
    created() {
        Utils.getInformation().then(response => {
            if (response.status === 200) {
                this.should_validate_cpf = response.data.should_validate_cpf

                addNoteToField('field-nomeCompleto', this.should_validate_cpf ? 'Digite seu CPF para preencher automático' : 'conforme escrito no documento de identificação')
            }
        })
    },
    computed: {
        should_allow_editing_cpf: function () {
            if (this.should_validate_cpf && this.model.nr_cpf?.length) {
                return true
            }

            const birth = moment(this.model.dt_nascimento, 'DD/MM/YYYY')
            const today = moment()
            const age = today.diff(birth, 'years')
            const isAdult = age >= 18

            return isAdult && this.should_validate_cpf
        }
    },
    watch: {
        'model.nr_cpf': {
            handler: async function (newVal) {
                if (newVal.length === 14 && this.should_validate_cpf) {
                    const cpf_response = await Utils.getCPFInfo({
                        cpf: onlyNumbers(newVal)
                    })
                    if (!cpf_response.done) {
                        this.$buefy.toast.open({
                            type: 'is-danger', position: 'is-bottom', duration: 4000,
                            message: cpf_response.message
                        })
                        this.model.nm_pessoa_fisica = '';
                        this.model.dt_nascimento = '';

                        return
                    }

                    const parsedDate = moment(cpf_response.data.nascimento, 'DDMMYYYY');
                    const year = parsedDate.format('YYYY');
                    const formattedDate = parsedDate.format(`DD/MM/${year}`);

                    this.serpro_cpf = {
                        dt_nascimento: formattedDate,
                        nm_pessoa_fisica: cpf_response.data.nome
                    }
                    this.model.nm_pessoa_fisica = cpf_response.data.nome
                }
            },
            deep: true
        }
    },
    validations() {
        const birth = moment(this.model.dt_nascimento, 'DD/MM/YYYY')
        const today = moment()
        const age = today.diff(birth, 'years')
        const isAdult = age >= 18

        let v = {
            model: {
                nm_pessoa_fisica: {
                    required,
                    minLength: minLength(8),
                    fullName: val => val ? val.includes(' ') : false
                },
                nr_cpf: {
                    required: requiredIf(isAdult),
                    validateCpf: isAdult ? validateCpf : true
                },
                nr_telefone: { required, validateFone },
                dt_nascimento: {
                    required, minLength: minLength(10),
                    validated_serpro: val => {
                        if (!isAdult && !this.model.nr_cpf?.length) return true
                        if (!this.should_validate_cpf) return true
                        return this.serpro_cpf ? val === this.serpro_cpf.dt_nascimento : false
                    },
                    dateValid: val => moment(val, 'DD/MM/YYYY').isValid()
                },
                ie_sexo: { required }
            }
        }
        return v
    },
    methods: {
        model_message() {

            let result = { nm_pessoa_fisica: '', nr_cpf: '', nr_telefone: '', dt_nascimento: '', ie_sexp: '' }

            if (this.model.submitted) {
                if (!this.$v.model.nm_pessoa_fisica.required) result.nm_pessoa_fisica = 'Digite o nome'
                else if (!this.$v.model.nm_pessoa_fisica.minLength) result.nm_pessoa_fisica = 'Nome deve conter pelo menos 8 caracteres'
                else if (!this.$v.model.nm_pessoa_fisica.fullName) result.nm_pessoa_fisica = 'Digite o nome completo'

                if (!this.$v.model.nr_cpf.required) result.nr_cpf = 'Digite o CPF'
                else if (!this.$v.model.nr_cpf.validateCpf) result.nr_cpf = 'Digite um CPF válido'

                if (!this.$v.model.nr_telefone.required) result.nr_telefone = 'Digite o telefone'
                else if (!this.$v.model.nr_telefone.validateFone) result.nr_telefone = 'Digite um telefone nacional válido'

                if (!this.$v.model.dt_nascimento.required) result.dt_nascimento = 'Digite a data de nascimento'
                else if (!this.$v.model.dt_nascimento.minLength) result.dt_nascimento = 'Digite uma data válida'
                else if (!this.$v.model.dt_nascimento.dateValid) result.dt_nascimento = 'Digite uma data válida'
                else if (this.$v.model.nr_cpf.required && !this.$v.model.dt_nascimento.validated_serpro) result.dt_nascimento = 'Data de nascimento não confere com CPF'

                if (!this.$v.model.ie_sexo.required) result.ie_sexo = 'Selecione o sexo'

            }
            return result
        },
        handleSubmit() {
            this.model.submitted = true
            this.$v.$touch()
            if (this.$v.$invalid) return 0
            this.waiting.cadastrar = true
            let dataSend = Object.assign({}, this.model)

            let clean = ['reset', 'submitted']
            clean.forEach(i => delete dataSend[i])

            if (!dataSend.id) delete dataSend.id

            dataSend.dt_nascimento = moment(dataSend.dt_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
            let onlyNumbers = ['nr_cpf', 'nr_telefone']
            onlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

            dataSend.nr_telefone = `+55${dataSend.nr_telefone}`
            dataSend.nr_cpf = dataSend.nr_cpf ? dataSend.nr_cpf.match(/\d+/g).join('') : null

            if (this.is_titular) {
                Usuario.save(dataSend).then(response => {
                    this.waiting.cadastrar = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: dataSend.id ? 'Cadastro <b>atualizado</b>' : 'Nova pessoa <b>adicionada</b>'
                        })
                        this.$parent.close()
                        if (this.$parent.$parent.getPessoas) this.$parent.$parent.getPessoas()
                    } else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: response.data.detail })
                            else response.data.detail.forEach(msg => {
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: msg })
                            })
                        }
                    }
                })
                return
            }

            OutrasPessoas.saveOutraPessoa(dataSend).then(response => {
                this.waiting.cadastrar = false
                if ([200, 201, 204].includes(response.status)) {
                    this.$buefy.toast.open({
                        type: 'is-success', position: 'is-bottom',
                        message: dataSend.id ? 'Cadastro <b>atualizado</b>' : 'Nova pessoa <b>adicionada</b>'
                    })
                    this.$parent.close()
                    if (this.$parent.$parent.getPessoas) this.$parent.$parent.getPessoas()
                } else if (response.status === 400) {
                    const errors = Object.entries(response.data)
                    // eslint-disable-next-line no-unused-vars
                    for (const [_, errors_messages] of errors) {
                        for (const error_message of errors_messages) {
                            this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: error_message })
                        }
                    }
                }

            })
        },
        updateModel(props, isTitular) {
            this.model.id = props.id
            this.model.nm_pessoa_fisica = props.nm_pessoa_fisica
            this.model.nr_cpf = props.nr_cpf
            this.model.nr_telefone = props.nr_telefone
            this.model.dt_nascimento = props.dt_nascimento
            this.model.ie_sexo = props.ie_sexo
            this.is_titular = isTitular
        }
    }
}
</script>