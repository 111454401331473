<template lang="pug">
    .agendamento
        .flex.items-center.justify-center.flex-wrap
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                div(v-if='! agendamentoCriado')
                    .w-full
                        SelecionarExames(v-if='! agendamento.exames')
                        SelecionarEstabelecimento(v-else)

                div(v-else)
                    SelecionarPaciente
</template>

<script>
    import SelecionarExames from './Etapas/SelecionarExames'
    import SelecionarEstabelecimento from './Etapas/EstabelecimentoData'
    import SelecionarPaciente from './Etapas/Paciente'
    export default {
        components: { SelecionarExames, SelecionarEstabelecimento, SelecionarPaciente },
        data () {
            return {
                agendamento: {
                    ie_tipo_agenda: 'PP',
                    exames: null,
                    estabelecimento: null,
                    agenda: null,
                    paciente: null
                },
                agendamentoCriado: false,
                link_url: null,
            }
        }
    }
</script>