<template lang="pug">
    form.flex.justify-center(@submit.prevent='handleSubmit()')
        .modal-card.max-w-sm.p-4(class='md:p-0')
            header.modal-card-head
                p.modal-card-title Acesse
                button.delete(type='button' @click='modalProps.close()')
            section.modal-card-body
                b-field(label='E-mail:' :message="model_message().usuario"
                    :type="{ 'is-danger': model.submitted && $v.model.usuario.$error }")
                    b-input(type='email' icon='account-outline' v-model='$v.model.usuario.$model' placeholder='Seu email' rounded)

                b-field(label='Senha:' :message="model_message().senha"
                    :type="{ 'is-danger': model.submitted && $v.model.senha.$error }")
                    b-input(type='password' icon='lock-outline' v-model='$v.model.senha.$model' placeholder='Sua senha' rounded password-reveal)
                div(class="text-center w-full")
                    router-link.text-sm(:to="{ name: 'esqueciMinhaSenha' }" @click.native='modalProps.close()') Esqueceu sua senha?

            footer.modal-card-foot
                .text-center.w-full
                    b-button(class='w-2/3' type='is-primary' native-type='submit' rounded :loading='waiting.login') Entrar
                    .mt-3(v-if='cadastro')

                        router-link.text-sm(:to="{ name: 'cadastrar' }" @click.native='modalProps.close()')
                            a Cadastre-se




</template>

<script>
    import { Usuario } from './../../middleware'
    import { required, minLength, email } from 'vuelidate/lib/validators'
    export default {
        props: [ 'modalProps', 'cadastro', 'redirecionar' ],
        data () {

            const model = {
                usuario: null,
                senha: null,
                submitted: false,
                reset () {
                    Object.keys(this).forEach(key => {
                        if (!['reset'].includes(key)) this[key] = null
                        this.submitted = false
                    })
                }
            }
            
            return {
                model,
                waiting: { login: false }
            }
        },
        validations () {
            let v = {
                model: {
                    usuario: { required, email },
                    senha: { required, minLength: minLength(6) }
                }
            }
            return v
        },
        methods: {
            model_message () {
                let result = { usuario: '', senha: '' }
                if (this.model.submitted) {

                    if (! this.$v.model.usuario.required) result.usuario = 'Digite o e-mail'
                    else if (! this.$v.model.usuario.email) result.usuario = 'Digite um e-mail válido'

                    if (! this.$v.model.senha.required) result.senha = 'Digite a senha'
                    else if (! this.$v.model.senha.minLength) result.senha = 'Senha deve conter pelo menos 6 caracteres'

                }
                return result
            },
            handleSubmit () {
                this.model.submitted = true
                this.$v.$touch(); if (this.$v.$invalid) return 0

                this.waiting.login = true
                Usuario.login(this.model.usuario, this.model.senha).then(response => {
                    this.waiting.login = false
                    console.log(response)
                    if (response.usuario) {
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: 'Usuário <b>autenticado</b>'
                        })

                        let $parent = this.$parent
                        while ($parent) {
                            if ($parent.hasOwnProperty('usuario')) $parent.usuario = response.usuario // eslint-disable-line
                            if ($parent.hasOwnProperty('$parent')) $parent = $parent.$parent // eslint-disable-line
                        }

                        if (this.redirecionar && this.$route.name !== 'home') this.$router.push({ name: 'home' })
                    } else {
                        if (response.status === 500)
                            this.$buefy.toast.open({
                                type: 'is-danger', position: 'is-bottom',
                                message: 'Sem conexão com o servidor'
                            })
                        else if (response.data.detail)
                            this.$buefy.toast.open({
                                type: 'is-danger', position: 'is-bottom',
                                message: response.data.detail
                            })
                        else this.$buefy.toast.open({
                            type: 'is-danger', position: 'is-bottom',
                            message: 'Login inválido'
                        })
                    }
                    this.$router.go()
                })
                
            },
        }
    }
</script>